/**
 * Created by leo108 on 2018/7/31.
 */

import $ from 'jquery'
import _each from 'lodash/each'
import _findIndex from 'lodash/findIndex'
import _keys from 'lodash/keys'
import _sortBy from 'lodash/sortBy'
import _trim from 'lodash/trim'
import _trimStart from 'lodash/trimStart'
import _camelCase from 'lodash/camelCase'
import _isArray from 'lodash/isArray'
import compact from 'lodash/compact'
import axios from 'axios'
import createHash from 'create-hash'
import { DateTime } from 'luxon'
import Mustache from 'mustache'
import raw_anchorme from 'anchorme'
import URI from 'urijs'
// eslint-disable-next-line import/no-webpack-loader-syntax
import videoEmailTpl from 'raw-loader!../../views/video/email_template.mustache'
import toastr from 'toastr'
import markdownit from 'markdown-it'
import { FilterXSS } from 'xss'

export function resize_to_contain (originalWidth, originalHeight, maxWidth, maxHeight) {
  let height, width
  const ratio = originalWidth / originalHeight
  if (ratio > maxWidth / maxHeight) {
    // too wide
    height = originalHeight / (originalWidth / maxWidth)
    width = maxWidth
  } else {
    // too high
    width = originalWidth / (originalHeight / maxHeight)
    height = maxHeight
  }

  return { width, height }
}

export function facebook_share_link (url, utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '') {
  const data = { utm_source, utm_medium, utm_campaign, utm_content }

  return 'https://www.facebook.com/dialog/share?' + URI.buildQuery({ app_id: process.env.MIX_FACEBOOK_ID, display: 'page', href: append_query(main_url(url), data) })
}

export function facebook_messenger_share_link (url, utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '') {
  const data = { utm_source, utm_medium, utm_campaign, utm_content }

  return 'fb-messenger://share?' + URI.buildQuery({ link: append_query(main_url(url), data), app_id: '101078520243269' })
}

export function linkedin_share_link (url, utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '') {
  const data = { utm_source, utm_medium, utm_campaign, utm_content }

  const queries = { url: append_query(main_url(url), data) }

  return 'https://www.linkedin.com/sharing/share-offsite/?' + URI.buildQuery(queries)
}

export function twitter_share_link (title, pic, url, utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '') {
  const data = { utm_source, utm_medium, utm_campaign, utm_content }

  const text = `${title} ${append_query(main_url(url), data)}`

  return 'https://x.com/intent/tweet?' + URI.buildQuery({ text })
}

export function email_share_link (email, title, body, url, utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '') {
  const data = { utm_source, utm_medium, utm_campaign, utm_content }
  const line = [body, escape(append_query(main_url(url), data))]

  return `mailto:${email}?subject=${title}&body=${line.join('%0A%0A')}`
}

export function sms_share_link (phone, body, url, utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '') {
  let data = { utm_source, utm_medium, utm_campaign, utm_content }
  data = filter_empty_key(data)

  let protocol = 'sms:'
  let connect = '&'
  if (/android/i.test(navigator.userAgent)) {
    protocol = 'sms://'
    connect = '?'
  }
  return `${protocol}${phone || 'contact_number_here'}${connect}body=${body} ${encodeURIComponent(append_query(main_url(url), data))}`
}

export function main_url (url) {
  const uri = new URI(url)
  uri.hostname(process.env.MIX_APP_DOMAIN)
  uri.scheme('https')

  return uri.toString()
}

export function email_url (url) {
  const uri = new URI(url)
  uri.hostname(get_email_domain())
  uri.scheme('https')

  return uri.toString()
}

export function get_email_domain () {
  const emailDomains = process.env.MIX_EMAIL_DOMAINS.split(',')
  let availableDomains = emailDomains

  if (Spark.settings[window.constants.App_Models_Setting.SETTING_DISABLED_EMAIL_DOMAINS].length > 0) {
    availableDomains = emailDomains.filter(domain => Spark.settings[window.constants.App_Models_Setting.SETTING_DISABLED_EMAIL_DOMAINS].indexOf(domain) < 0)
  }

  if (availableDomains.length === 0) {
    availableDomains = emailDomains
  }

  return availableDomains[Math.floor(Math.random() * availableDomains.length)]
}

export function url_without_scheme (url) {
  return url.replace(/(^\w+:|^)\/\//, '')
}

export function append_query (url, queries) {
  const newQueries = {}

  _each(queries, (value, key) => {
    if (value !== null && _trim(value) !== '') {
      newQueries[key] = _trim(value)
    }
  })

  if (_keys(newQueries).length === 0) {
    return url
  }

  const result = URI.parse(url)
  const separator = result.query ? '&' : '?'

  return url + separator + URI.buildQuery(newQueries)
}

export function remove_query (url, keys) {
  const uri = new URI(url)

  uri.removeQuery(keys)

  return uri.toString()
}

export function renderVideoHtml (ipHash, video, options) {
  const url = new URI(video.url)
  let finalUrl = new URI(video.url)
  const trackParams = options.track_params ? options.track_params : {}

  finalUrl = finalUrl.addQuery(trackParams)
  const emailDomain = get_email_domain()

  if (options.from_email || options.email_placeholder) {
    finalUrl = finalUrl.hostname(emailDomain).addQuery('from_email', '1')
  }

  if (options.email_placeholder) {
    for (const field of ['email', 'contact', 'ee', 'th']) {
      delete trackParams[field]
      finalUrl = finalUrl.removeQuery(field)
    }

    finalUrl = finalUrl.toString() + '&email=' + options.email_placeholder
  }

  let emailPreview = video.gif_with_play_path ?? 'https://' + emailDomain + Spark.router('web.video.gif_with_play_url', { canonical: video.canonical })

  if (options.email_preview) {
    emailPreview = options.email_preview
  }

  let trackPixel

  if (options.disable_tracking_pixel) {
    trackPixel = false
  } else {
    const pixelParam = { video: video.canonical, iat: DateTime.now().toUnixInteger(), hash: ipHash, ...trackParams }

    trackPixel = 'https://' + emailDomain + Spark.router('web.track.pixel', pixelParam)

    if (options.email_placeholder) {
      trackPixel += '&email=' + options.email_placeholder
    }
  }

  let vars = {
    url: finalUrl,
    short_url: `${url.hostname()}${url.path()}`,
    email_preview: emailPreview,
    title: video.title,
    track_pixel: trackPixel,
    div_style: video.cover ? `background: url(${video.cover_path}); background-size: cover; display: inline-block;` : '',
    table_bg: video.cover ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
    cta_color: video.switches.cta_color,
    message: options.message ? options.message : false
  }

  const resolution = !video.video_resolution ? { width: 'auto', height: 'auto' } : resize_to_contain(video.video_resolution.width, video.video_resolution.height, 600, 400)
  vars = Object.assign(vars, resolution)

  if (!options.extra_vars) {
    options.extra_vars = {}
  }

  if (typeof options.extra_vars.show_watch_button === 'undefined') {
    options.extra_vars.show_watch_button = true
  }

  if (options.extra_vars.show_watch_button) {
    options.extra_vars.watch_button_text = _trim(Mustache.render(Spark.state.user.k_v_settings[window.constants.App_Models_UserSetting.WATCH_VIDEO_BUTTON_TEXT], {
      duration: video.formatted_duration ? `(${video.formatted_duration})` : ''
    }))
  }

  vars = Object.assign(vars, options.extra_vars)

  return Mustache.render(videoEmailTpl, vars)
}

export function filter_empty_key (obj) {
  const result = {}
  for (const key in obj) {
    if (obj[key] !== '') {
      result[key] = obj[key]
    }
  }
  return result
}

export function is_touchable_device () {
  return 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

export function is_iOS_safari () {
  const ua = window.navigator.userAgent
  return ua.match(/iPad/i) || ua.match(/iPhone/i)
}

export function is_safari () {
  const ua = window.navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1
}

export function is_android () {
  return /android/i.test(navigator.userAgent)
}

export function is_small_screen () {
  return window.screen && window.screen.width <= 540
}

export function is_cheap_sms_code (countryCode) {
  return window.constants.GLOBAL.CHEAP_SMS_CODES.indexOf(countryCode) >= 0
}

export function invitation_url (forEmail = true) {
  if (Spark.state && Spark.state.user && Spark.userHasAffiliatePartner() && Spark.state.user.profile.username !== null) {
    const path = Spark.router('web.affiliate.redirect', { username: Spark.state.user.profile.username })

    if (forEmail) {
      return email_url(path)
    } else {
      return main_url(path)
    }
  }

  return ''
}

export function format_money (amount) {
  return parseFloat(amount.toFixed(2))
}

export function decimal_beatify (value) {
  return value.toFixed(2).replace(/\.00$/, '')
}

export function pagination (extraMeta = {}) {
  return {
    data: [],
    meta: Object.assign({
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 15,
      to: 0
    }, extraMeta)
  }
}

export function replace_list (oldList, newList, predicate, sortBy = null, reverse = false) {
  newList.forEach(item => {
    const index = _findIndex(oldList, predicate(item))
    if (index === -1) {
      oldList.push(item)
    } else {
      oldList[index] = {
        ...oldList[index],
        ...item
      }
    }
  })

  if (sortBy !== null) {
    oldList = _sortBy(oldList, sortBy)

    if (reverse) {
      oldList = oldList.reverse()
    }
  }

  return oldList
}

export function chrome_extension_installed () {
  return window.dubb_chrome_extension_installed === true
}

export function anchorme (str) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  }

  return raw_anchorme({
    input: str.replace(/[&<>"']/g, m => map[m]),
    options: {
      attributes: {
        target: '_blank'
      }
    }
  })
}

export function remove_location_queries (names) {
  window.history.replaceState(null, null, remove_query(location.href, names))
}

export function rain_emoji (emoji, number) {
  const images = []
  const isMobile = is_small_screen()

  function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min)) + min
  }

  for (let i = 0; i < number; i++) {
    const $image = $(`<span>${emoji}</span>`)
      .css('position', 'fixed')
      .css('top', '-20px')
      .css('font-size', getRandomInt(12, 48) + 'px')
      .css('left', getRandomInt(isMobile ? 10 : 25, isMobile ? 90 : 75) + 'vw')
      .css('z-index', '10000000')
    $('body').append($image)
    images.push($image)
  }

  images.forEach($image => {
    $image.animate({
      top: '110vh'
    }, getRandomInt(1000, 3000))
  })
}

export function sha256 (str) {
  return createHash('sha256').update(str).digest('hex')
}

export function short_sha256 (str) {
  return sha256(str).substring(0, 32)
}

export function load_script (src, cb = null) {
  const id = `ddl-${sha256(src)}`
  let s = document.getElementById(id)

  if (s) {
    if (cb) {
      s.addEventListener('load', () => {
        cb()
      })
      cb()
    }
    return
  }

  s = document.createElement('script')
  s.type = 'text/javascript'
  s.src = src
  s.id = id

  if (cb) {
    s.onload = () => {
      cb()
    }
  }

  document.body.appendChild(s)
}

export function hex_to_rgb (h) {
  let r = '0'
  let g = '0'
  let b = '0'

  // 3 digits
  if (h.length === 4) {
    r = '' + h[1] + h[1]
    g = '' + h[2] + h[2]
    b = '' + h[3] + h[3]

    // 6 digits
  } else if (h.length === 7) {
    r = '' + h[1] + h[2]
    g = '' + h[3] + h[4]
    b = '' + h[5] + h[6]
  }

  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)]
}

export function readable_color (bg) {
  if (bg.startsWith('#')) {
    bg = bg.replace('#', '')
  }
  const r = parseInt(bg.substring(0, 2), 16)
  const g = parseInt(bg.substring(2, 2), 16)
  const b = parseInt(bg.substring(4, 2), 16)

  const squared_contrast = (
    r * r * 0.299 +
    g * g * 0.587 +
    b * b * 0.114
  )

  if (squared_contrast > Math.pow(130, 2)) {
    return [0, 0, 0]
  } else {
    return [255, 255, 255]
  }
}

export function darken_color (h, amt) {
  const rgb = hex_to_rgb(h)
  const res = []

  for (let n of rgb) {
    n = n - amt
    if (n > 255) {
      n = 255
    } else if (n < 0) {
      n = 0
    }

    res.push(n)
  }

  return res
}

export function set_css_video_color (video) {
  const rootStyle = $(':root')[0].style
  rootStyle.setProperty('--video-color', hex_to_rgb(video.switches.video_color).join(','))
  rootStyle.setProperty('--video-header-color', hex_to_rgb(video.switches.header_color).join(','))
  rootStyle.setProperty('--video-frame-color', hex_to_rgb(video.switches.frame_color).join(','))
  rootStyle.setProperty('--cta-color', hex_to_rgb(video.switches.cta_color).join(','))
  rootStyle.setProperty('--cta-text-color', hex_to_rgb(video.switches.cta_text_color).join(','))
}

export function number_with_commas (x) {
  const value = parseFloat(x)

  if (!isFinite(value) || (!value && value !== 0)) {
    return ''
  }

  return value.toLocaleString('en-US')
}

export function is_unlimited_quota (quota) {
  return quota === -1
}

export function download_file (url, cb = null) {
  axios.get(url, { responseType: 'blob' })
    .then(response => {
      let filename = ''
      const disposition = response.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      const blob = response.data
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
      window.URL.revokeObjectURL(link.href)
      cb && cb()
    })
}

export function resolve_route_url ($router, to) {
  return $router.resolve(to).href
}

export function get_route_array_query ($route, name) {
  const arrayName = name + '[]'
  if (typeof $route.query[arrayName] === 'undefined') {
    return []
  }
  const values = $route.query[arrayName]

  if (_isArray(values)) {
    return values
  } else {
    return [values]
  }
}

export function is_null_or_empty (value) {
  return value === null || value === ''
}

export function compile_tpl (tpl, vars) {
  for (const key in vars) {
    vars[_camelCase(key.replace(/^{{|}}$/g, ''))] = vars[key]
  }
  let item
  while ((item = /{{([^}]+?)}}/.exec(tpl)) !== null) {
    const placeholder = item[0]
    const components = item[1].split('|')
    const varName = _camelCase(components[0])
    const defaultVal = components.length > 1 ? components[1] : ''
    const value = typeof vars[varName] === 'undefined' || vars[varName] === '' || vars[varName] === null ? defaultVal : vars[varName]
    tpl = tpl.replace(placeholder, value)
  }

  return tpl
}

export function download_csv (filename, rows) {
  const process_row = function (row) {
    let finalVal = ''
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString()
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString()
      }

      let result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) { result = '"' + result + '"' }
      if (j > 0) { finalVal += ',' }
      finalVal += result
    }
    return finalVal + '\n'
  }

  let csvFile = ''
  for (let i = 0; i < rows.length; i++) {
    csvFile += process_row(rows[i])
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function simple_toastr (msg) {
  toastr.success(msg, '', {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  })
}

export function to_12_clock (time) {
  const parts = time.split(':')
  let hour = parseInt(_trimStart(parts[0], '0').padStart(1, '0'))
  let minute = parts.length > 1 ? parseInt(_trimStart(parts[1], '0').padStart(1, '0')) : 0
  let period
  minute = minute < 60 && minute >= 0 ? minute : 0

  if (hour < 12) {
    period = 'AM'
  } else {
    period = 'PM'
    hour -= 12
  }

  if (hour === 0) {
    hour = 12
  }

  return {
    hour,
    minute,
    period,
    toString: () => `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`
  }
}

export function to_24_clock (hour, minute, period) {
  if (period === 'PM' && hour < 12) {
    hour += 12
  } else if (period === 'AM' && hour === 12) {
    hour = 0
  }

  return {
    hour,
    minute,
    toString: () => `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
  }
}

export function word_count (paragraph) {
  return compact(paragraph.split(/\s+/)).length
}

export function image_url_to_base64 (url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = function () {
      reject(new Error('Failed to load image'))
    }
    img.src = url
  })
}

export function auto_correct_url (url) {
  if (!url) {
    return ''
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }

  return url
}

export function convert_markdown (text, converter_options = {}, filter_xss_options = {}) {
  if (!text) {
    return ''
  }

  const md = markdownit({
    breaks: true,
    linkify: true,
    ...converter_options
  })

  const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options)
  }

  md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    tokens[idx].attrSet('target', '_blank')
    tokens[idx].attrSet('rel', 'nofollow')

    return defaultRender(tokens, idx, options, env, self)
  }

  return (new FilterXSS({
    whiteList: {
      a: ['href', 'title', 'target', 'rel'],
      img: ['src', 'alt'],
      h1: [],
      h2: [],
      h3: [],
      h4: [],
      h5: [],
      h6: [],
      p: [],
      br: [],
      strong: [],
      em: [],
      hr: [],
      pre: [],
      code: [],
      blockquote: [],
      li: [],
      ol: [],
      ul: []
    },
    css: false,
    ...filter_xss_options
  })).process(md.render(text))
}
