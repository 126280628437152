export default {
  VIRTUAL_BACKGROUND_URL: 'virtual-background-url',

  // Register Form
  GUEST_VIDEO_SLUG: 'guest_video_slug',
  PARTNER: 'partner',
  REFERRER_URL: 'referrer_url',
  LANDING_URL: 'landing_url',

  UTM: {
    SOURCE: 'utm:source',
    MEDIUM: 'utm:medium',
    CAMPAIGN: 'utm:campaign',
    CONTENT: 'utm:content',
    TERM: 'utm:term'
  },

  MESSAGING: {
    USE_CTRL: 'messaging:use-ctrl',
    DEFAULT_TAB: 'messaging:default-tab'
  },

  STOP_REQUEST_NOTIFICATION_PERMISSION: 'stop-request-notification-permission',

  DASHBOARD: {
    SHOW_AI_ASSISTANT: 'dashboard:show-ai-assistant',
    STAT_CARDS_NAVIGATE_BUTTON_CLICKED: 'dashboard:stat-cards-navigate-button-clicked',
    STAT_CARDS_NAVIGATE_TO: 'dashboard:stat-cards-navigate-to',

    ONE_ON_ONE_MODAL_SHOWN_AT: 'dashboard:one-on-one-modal-shown-at',

    SETUP_CRM_TASKS_MODAL: {
      HIDDEN: 'dashboard:setup-crm-tasks-modal:hidden'
    },

    UPDATE_TEAM_DOMAIN_MODAL: {
      HIDDEN: 'dashboard:update-team-domain-modal:hidden'
    },

    FILTERS: {
      CAMPAIGN_LIST: {
        CREATOR_ID: 'dashboard:filters:campaign-list:creator_id'
      },
      CONTACT_LIST: {
        OWNER_ID: 'dashboard:filters:contact-list:owner_id'
      },
      CTA_LIST: {
        USER_ID: 'dashboard:filters:cta-list:user_id'
      },
      DEAL_LIST: {
        OWNER_ID: 'dashboard:filters:deal-list:owner_id',
        PIPELINE_ID: 'dashboard:filters:deal-list:pipeline_id'
      },
      SCRIPT_LIST: {
        OWNER_ID: 'dashboard:filters:script-list:owner_id'
      },
      SHOWCASE_LIST: {
        USER_ID: 'dashboard:filters:showcase-list:user_id'
      },
      TASK_LIST: {
        ASSIGNEE_ID: 'dashboard:filters:task-list:assignee_id'
      },
      VIDEO_LIST: {
        USER_ID: 'dashboard:filters:video-list:user_id'
      },
      VIDEO_PRESET_LIST: {
        OWNER_ID: 'dashboard:filters:video-preset-list:owner_id'
      },
      WORKFLOW_LIST: {
        CREATOR_ID: 'dashboard:filters:workflow-list:creator_id'
      }
    }
  },

  EMAIL_TEMPLATE: {
    CHANGED: 'email-template:changed'
  },

  WEBCAM_RECORDER: {
    CAMERA_DEVICE_ID: 'webcam-recorder:camera-device-id',
    MICROPHONE_DEVICE_ID: 'webcam-recorder:microphone-device-id',
    VIDEO_QUALITY: 'webcam-recorder:video-quality',
    IS_MIRROR_VIDEO: 'webcam-recorder:is-mirror-video',
    IS_DISPLAY_TELEPROMPTER: 'webcam-recorder:is-display-teleprompter',
    IS_BLUR_BACKGROUND: 'webcam-recorder:is-blur-background',
    BLUR_AMOUNT: 'webcam-recorder:blur-amount',
    IS_VIRTUAL_BACKGROUND: 'webcam-recorder:is-virtual-background',
    VIRTUAL_BACKGROUND_IMAGE: 'webcam-recorder:virtual-background-image',
    EDGE_BLUR_AMOUNT: 'webcam-recorder:edge-blur-amount'
  },

  VOICE_RECORDER: {
    VOICE: 'voice-recorder:voice',
    VOICE_SPEED: 'voice-recorder:voice-speed',
    VOICE_PITCH: 'voice-recorder:voice-pitch',
    INCLUDE_PROFILE_PHOTO: 'voice-recorder:include-profile-photo',
    BACKGROUND_COLOR: 'voice-recorder:background-color',
    BAR_COLOR: 'voice-recorder:bar-color',
    SIZE: 'voice-recorder:size'
  },

  AVATAR_VIDEO: {
    IMAGE_SOURCE: 'avatar-video:image-source',
    HAS_VISITED: 'avatar-video:has-visited',
    AI_ASSISTANT: {
      TASK: 'avatar-video:ai-assistant:task',
      TONES: 'avatar-video:ai-assistant:tones',
      WORD_COUNT: 'avatar-video:ai-assistant:word-count'
    }
  },

  AI_ASSISTANT: {
    LEAD_TEXT: 'ai-assistant:lead-text',
    INDUSTRY: 'ai-assistant:industry',
    RESPONSE: 'ai-assistant:response',
    TONES: 'ai-assistant:tones',
    WORD_COUNT: 'ai-assistant:word-count',
    HASH_AI_PROFILE_ID: 'ai-assistant:hash-ai-profile-id'
  },

  CONTACT: {
    SELECTED_PROPERTIES: 'contact:selected-properties'
  },

  VIDEO_EDIT: {
    PREVIEW_BUTTON_CLICKED: 'video-edit:preview-button-clicked'
  },
  VIDEO_LIST: {
    MODE: 'video-list:mode'
  },
  VIDEO_SHOW: {
    TRACK_DATA: 'video-show:track-data'
  }
}
